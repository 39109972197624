import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const ServicesGrid = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      stickyPolygonLeft: file(relativePath: { eq: "global/polygon-left.svg" }) {
        publicURL
      }
      stickyPolygonRight: file(
        relativePath: { eq: "global/polygon-right.svg" }
      ) {
        publicURL
      }
      disinfecting: file(
        relativePath: { eq: "home/services/1 service - resort cleaning.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      housekeeping: file(
        relativePath: {
          eq: "home/services/2 home services - hotel housekeeping.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      hospitalityStaffing: file(
        relativePath: {
          eq: "home/services/3 home services - hotel hospitality and staffing.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      commercialCleaning: file(
        relativePath: {
          eq: "home/services/4 home services - commercial cleaning.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      restorationServices: file(
        relativePath: {
          eq: "home/services/5 home services - restoration services.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      resortCleaning: file(
        relativePath: {
          eq: "home/services/6 home services - resort celaning.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      spaCleaning: file(
        relativePath: { eq: "home/services/7 home services - spa cleaning.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      restaurantCleaning: file(
        relativePath: {
          eq: "home/services/8 home services - restaurant cleaning.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      commercialKitchen: file(
        relativePath: {
          eq: "home/services/9 home services - commercial kitchen.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
      floorCare: file(
        relativePath: {
          eq: "home/services/10 homepage services - floor care.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 227)
        }
      }
    }
  `);

  const content = [
    {
      image: data.resortCleaning.childImageSharp.gatsbyImageData,
      heading: "Resort Cleaning",
      link: "/resort-cleaning/",
    },
    {
      image: data.restaurantCleaning.childImageSharp.gatsbyImageData,
      heading: "Restaurant Cleaning",
      link: "/restaurant-cleaning/",
    },
    {
      image: data.commercialKitchen.childImageSharp.gatsbyImageData,
      heading: "Commercial Kitchen Cleaning",
      link: "/commercial-kitchen-cleaning/",
    },
    {
      image: data.spaCleaning.childImageSharp.gatsbyImageData,
      heading: "Spa Cleaning",
      link: "/spa-cleaning/",
    },
    {
      image: data.commercialCleaning.childImageSharp.gatsbyImageData,
      heading: "Commercial Cleaning",
      link: "/commercial-cleaning/",
    },
    {
      image: data.floorCare.childImageSharp.gatsbyImageData,
      heading: "Hard Surface Floor Care",
      link: "/hard-surface-floor-care/",
    },
    {
      image: data.disinfecting.childImageSharp.gatsbyImageData,
      heading: "Hotel Disinfecting",
      link: "/hotel-disinfecting/",
    },
    {
      image: data.housekeeping.childImageSharp.gatsbyImageData,
      heading: "Hotel Housekeeping",
      link: "/hotel-housekeeping/",
    },
    {
      image: data.hospitalityStaffing.childImageSharp.gatsbyImageData,
      heading: "Hotel hospitality and staffing",
      link: "/hospitality-staffing/",
    },
    {
      image: data.restorationServices.childImageSharp.gatsbyImageData,
      heading: "Restoration Services",
      link: "/hotel-restoration/",
    },
  ];

  return (
    <section className={`${className || "mb-28 md:mb-36"}`}>
      <div className="container">
        <header className="mb-12 text-center md:text-left">
          <h2>Our Services</h2>
        </header>

        <div className="relative md:hidden sticky top-32">
          <img
            src={data.stickyPolygonLeft.publicURL}
            alt="Sticky Polygon Left"
            className="absolute -left-5"
          />
          <img
            src={data.stickyPolygonRight.publicURL}
            alt="Sticky Polygon Right"
            className="absolute -right-4"
          />
        </div>

        <div className="grid md:grid-cols-4 lg:grid-cols-5 md:gap-x-4 gap-y-6 md:gap-y-8">
          {content.map((content, i) => {
            return (
              <AniLink
                fade
                to={content.link}
                className="relative mx-auto md:mx-0 text-white hover:text-white"
                key={i}
              >
                <div className="group rounded-t-custom overflow-hidden">
                  <GatsbyImage
                    image={content.image}
                    className="relative left-[-1px]"
                  />

                  <div className="absolute bottom-6 inset-x-0 px-1.5 text-center tracking-widest font-bold uppercase z-20">
                    {content.heading}
                  </div>
                  <div className="absolute w-full h-0 group-hover:h-full bg-gray-900/80 top-0 z-10 transition-all duration-500 ease-linear"></div>
                </div>
              </AniLink>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServicesGrid;
